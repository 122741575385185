<template>
  <div>
    <!-- 底栏容器 -->
      <div class="footer_wrap">
        <div class="footer_box type_area">
          <div class="footer_top">
            <img class="footer_top_item" src="@/assets/images/index/pzbz.png" alt="">
            <img class="footer_top_item" src="@/assets/images/index/qsgy.png" alt="">
            <img class="footer_top_item" src="@/assets/images/index/jsps.png" alt="">
            <img class="footer_top_item" src="@/assets/images/index/shwy.png" alt="">
          </div>
          <div class="footer_middle">
            <div class="footer_middle_left">
              <img class="left_img" src="@/assets/images/index/footer_jt.svg" alt="">
              <div class="left_item" v-for="(item,index) in articleList" :key="index">
                <span class="left_item_title">{{item.categoryName}}</span>
                <span class="left_item_text" v-for="(item1,index1) in item.childen" :key="index1" @click="goToExternalPage(item1.id,item.categoryName)">{{item1.title}}</span>
              </div>
            </div>
            <div class="footer_middle_right">
              <img style="width: 222px;margin-right: -10px;" src="@/assets/images/index/logo_a.png" alt="">
              <!-- <span class="txt">全国服务热线</span>
              <span class="phone">0551-63677603</span> -->
            </div>
          </div>
          <div class="footer_bottom">
            <div class="bottom_top">
              <span class="top_item" @click="pageJump('/index')">首页</span>
              <span class="top_item" @click="pageJump('/onlineOrder')">快捷采购</span>
              <span class="top_item" @click="goToPJExternalPage('/special/index')">今日特价</span>
              <span class="top_item" @click="goToPJExternalPage('/promotion/index')">促销专区</span>
              <span class="top_item" @click="goToPJExternalPage('/user/buyrecord')">采购历史</span>
            </div>
            <div class="bottom_center">
              <div class="center_item">互联网药品信息服务资格证编号</div>
              <div class="center_item hfjz" @click="goToPJExternalPage('/picture/system/hlw.jpg',1)">(皖)-非经营性-2019-0039</div>
              <div class="center_item hfjz" @click="goToExternalPage('https://beian.miit.gov.cn/#/Integrated/index',1)">
                <img src="@/assets/images/index/footer_icon.png" alt="">
                <span>皖ICP14002424号-1</span>
              </div>
              <div class="center_item hfjz" @click="goToPJExternalPage('/picture/system/qx.jpg',1)">医疗器械经营许可证号：皖合食药监械 经营许可证许20180487</div>
            </div>
            <div class="bottom_bottom">版权所有：<span class="hfjz" style="margin-right: 8px;" @click="goToExternalPage('https://www.hrjkjt.com/',1)">安徽华人健康股份有限公司</span>技术支持：<span class="hfjz" @click="goToExternalPage('https://www.fastjz.com/',1)">合肥捷助医药科技有限公司</span></div>
          </div>
        </div>
      </div>
    <!-- 底栏容器END -->
  </div>
</template>
<script>
export default {
  name: 'Footer',
  data() {
    return {
      articleList: [],
    };
  },
  created() {
    // 获取底部文章
    this.getPcArticle()
  },
  methods: {
    // 获取底部文章
    getPcArticle(){
      this.$api.pcArticle().then((res) => {
        if (res.data.code === 200) {
          this.articleList = res.data.data
        }
      });
    },
    // 页面跳转
    pageJump(url){
      this.$router.push(url)
    },
    // 链接跳转
    goToExternalPage(id,num) {
      if(num == 1){
        window.open(id)
      }else{
        // 页面跳转增加loading
        const loading = this.$loading();
        // window.location.href = id;
        this.$router.push({
          path: '/helpCenter',
          query: {
            id,num
          }
        })
        loading.close()
      }
    },
    // 拼接链接跳转
    goToPJExternalPage(url,num) {
      if(num == 1){
        window.open(process.env.VUE_APP_TZURL + url)
      }else{
        // 页面跳转增加loading
        const loading = this.$loading();
        window.location.href = process.env.VUE_APP_TZURL + url;
        loading.close()
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.footer_wrap{
  background: #ffffff;
  .footer_box{
    padding: 20px 56px;

    .footer_top{
      // padding: 0 85px 0 49px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      font-size: 22px;
      color: #333333;
      margin-bottom: 32px;

      .footer_top_item{
        width: 133px;
      }
    }
    .footer_middle{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 20px;

      .footer_middle_left{
        flex: 1;
        display: flex;
        align-items: flex-start;

        .left_img{
          margin-right: 24px;
          padding-top: 4px;
        }

        .left_item{
          flex: 1;
          display: flex;
          flex-direction: column;

          .left_item_title{
            font-weight: bold;
            font-size: 16px;
            color: #333333;
            margin-bottom: 10px;
          }

          .left_item_text{
            font-size: 14px;
            color: #666666;
            margin-bottom: 4px;
            text-indent: 5px;
            transition: 0.3s;

            &:hover{
              color: #999;
              cursor: pointer;
            }
          }
        }
      }
      .footer_middle_right{
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .txt{
          margin-top: 9px;
          font-size: 14px;
          color: #666666;
        }

        .phone{
          font-weight: 600;
          font-size: 26px;
          color: #D63103;
        }
      }
    }
    .footer_bottom{
      border-top: 1px solid #E2E2E2;
      font-size: 12px;
      color: #666666;
      
      .bottom_top{
        display: flex;
        align-items: center;
        justify-content: center;

        .top_item{
          position: relative;
          padding: 0 20px;
          margin: 10px 0;

          &:hover{
            color: #999;
            cursor: pointer;
            transition: 0.3s;
          }

          &::after{
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
            width: 1px;
            height: 8px;
            background: #B5B5B5;
          }
          &:last-of-type{
            &::after{
              width: 0px;
            }
          }
        }
      }
      .bottom_center{
        display: flex;
        align-items: center;
        justify-content: center;

        .center_item{
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          margin-bottom: 10px;

          img{
            margin-right: 9px;
          }

          &::after{
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
            width: 1px;
            height: 10px;
            background: #B5B5B5;
          }
          &:last-of-type{
            &::after{
              width: 0px;
            }
          }
        }
      }

      .hfjz{
        cursor: pointer;
        transition: .3s;

        &:hover{
          color: #999;
        }
      }
      .bottom_bottom{
        text-align: center;

      }
    }
  }
}

</style>